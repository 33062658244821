import { set_line_clamp } from './set_lineclamp';

export const get_dynamic_styles = (styles) => {
  let style_generate = {
    fontSize: styles?.text_size ?? '',
    lineHeight: styles?.line_height ?? '',
    fontWeight: styles?.font_weight ?? '',
    fontFamily: styles?.font_family ? `var(--${styles?.font_family})` : '',
    color: styles?.text_color?.startsWith('--')
      ? `var(${styles?.text_color})`
      : styles?.text_color ?? '',
    minWidth: styles?.min_width ?? '',
    minHeight: styles?.min_height ?? '',
    maxWidth: styles?.max_width ?? '',
    maxHeight: styles?.max_height ?? '',
    width: styles?.width ?? 'auto',
    height: styles?.height ?? 'auto',
    borderRadius: styles?.border_radius ?? '',
    borderTopLeftRadius: styles?.top_left_border_radius ?? '',
    borderTopRightRadius: styles?.top_right_border_radius ?? '',
    borderBottomLeftRadius: styles?.bottom_left_border_radius ?? '',
    borderBottomRightRadius: styles?.bottom_right_border_radius ?? '',
    borderColor: styles?.border_color?.startsWith('--')
      ? `var(${styles?.border_color})`
      : styles?.border_color ?? '',
    borderStyle: styles?.border_style ?? 'solid',
    position: styles?.position ?? '',
    zIndex: styles?.z_index ?? '',
    top: styles?.top ?? '',
    bottom: styles?.bottom ?? '',
    right: styles?.right ?? '',
    left: styles?.left ?? '',

    paddingRight:
      styles?.padding_right !== '0px' && styles?.padding_right !== undefined
        ? styles?.padding_right
        : styles?.padding_x ?? '',
    paddingLeft:
      styles?.padding_left !== '0px' && styles?.padding_left !== undefined
        ? styles?.padding_left
        : styles?.padding_x ?? '',
    paddingTop:
      styles?.padding_top !== '0px' && styles?.padding_top !== undefined
        ? styles?.padding_top
        : styles?.padding_y ?? '',
    paddingBottom:
      styles?.padding_bottom !== '0px' && styles?.padding_bottom !== undefined
        ? styles?.padding_bottom
        : styles?.padding_y ?? '',
    marginTop:
      styles?.margin_top !== '0px' && styles?.margin_top !== undefined
        ? styles?.margin_top
        : styles?.margin_y ?? '',
    marginBottom:
      styles?.margin_bottom !== '0px' && styles?.margin_bottom !== undefined
        ? styles?.margin_bottom
        : styles?.margin_y ?? '',
    marginLeft:
      styles?.margin_left !== '0px' && styles?.margin_left !== undefined
        ? styles?.margin_left
        : styles?.margin_left === '0px'
        ? 'auto'
        : styles?.margin_x
        ? styles?.margin_x === '0px'
          ? 'auto'
          : styles?.margin_x
        : '',
    marginRight:
      styles?.margin_right !== '0px' && styles?.margin_right !== undefined
        ? styles?.margin_right
        : styles?.margin_right === '0px'
        ? 'auto'
        : styles?.margin_x
        ? styles?.margin_x === '0px'
          ? 'auto'
          : styles?.margin_x
        : '',
    textAlign: styles?.text_align ?? '',
    backgroundImage: styles?.background_image_url
      ? `url('${styles?.background_image_url}')`
      : '',
    background: styles?.background?.startsWith('--')
      ? `var(${styles?.background})`
      : styles?.background ?? '',
    // backgroundColor: styles?.background_color ?? '',
    boxShadow: styles?.box_shadow ?? '',
    backgroundRepeat: styles?.background_repeat ?? 'no-repeat',
    backgroundSize: 'cover',
    // gridTemplateColumns: styles?.grid_template_columns ?? '',
    flexDirection: styles?.flex_direction ?? '',
    flexWrap: styles?.flex_wrap ?? '',
    alignItems: styles?.align_items ?? '',
    alignContent: styles?.align_content ?? '',
    justifyContent: styles?.justify_content ?? '',
    gap: styles?.gap ?? '',
    rowGap: styles?.row_gap ?? '',
    columnGap: styles?.column_gap ?? '',
    objectFit: styles?.object_fit ?? '',
    objectPosition: styles?.object_position ?? '',
    ...set_line_clamp(styles?.line_clamp),
  };

  for (let key in style_generate) {
    if (style_generate[key] === '' && style_generate[key]?.length === 0) {
      delete style_generate[key];
    }
  }

  return style_generate;
};
